import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Start Stimulus application
const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;

// Load and register global controllers
registerControllers(
  application,
  import.meta.glob('../controllers/*_controller.js', { eager: true }),
);

// Load and register view_components controllers
registerControllers(
  application,
  import.meta.glob('../../components/**/*_controller.js', { eager: true }),
);

import TextareaAutogrow from 'stimulus-textarea-autogrow';
application.register('textarea-autogrow', TextareaAutogrow);

// Error handling for missing Turbo frames
document.addEventListener('turbo:frame-missing', (event) => {
  const {
    detail: { response, visit },
  } = event;
  event.preventDefault();
  visit(response.url, {});
});
