import { Controller } from '@hotwired/stimulus';

const DIVISIONS = [
  { date: false, amount: 60, name: 'seconds' }, // per minute
  { date: false, amount: 60, name: 'minutes' }, // per hour
  { date: false, amount: 24, name: 'hours' }, // per day
  { date: true, amount: 7, name: 'days' }, // per week
  { date: true, amount: 4.34524, name: 'weeks' }, // per month
  { date: true, amount: 12, name: 'months' }, // per year
  { date: true, amount: Number.POSITIVE_INFINITY, name: 'years' },
];

export default class extends Controller {
  static values = {
    format: { type: String, default: 'time' },
  };

  static formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: 'auto',
  });

  connect() {
    const dateTime = new Date(this.element.dateTime);
    const duration = this.duration(dateTime);

    this.element.innerHTML = this.formatDuration(duration);
  }

  formatDuration(duration) {
    for (let i = 0; i <= DIVISIONS.length; i++) {
      const division = DIVISIONS[i];

      if (this.formatValue === 'date' && division.date === false) continue;

      if (Math.abs(duration) < division.amount)
        return this.constructor.formatter.format(
          Math.round(duration),
          division.name,
        );

      duration /= division.amount;
    }
  }

  duration(dateTime) {
    switch (this.formatValue) {
      case 'date':
        return this.durationInDays(dateTime);
      case 'time':
        return this.durationInSeconds(dateTime);
    }
  }

  durationInDays(dateTime) {
    const today = new Date();

    // Ensure that both dates have the same time
    today.setHours(0, 0, 0, 0);
    dateTime.setHours(0, 0, 0, 0);

    return (dateTime - today) / 1000 / (60 * 60 * 24);
  }

  durationInSeconds(dateTime) {
    return (dateTime - new Date()) / 1000;
  }
}
