import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['select'];

  update(event) {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;

    const newUrl = currentUrl;
    searchParams.set(event.target.name, event.target.value);
    newUrl.search = searchParams.toString();

    Turbo.visit(newUrl, { action: 'replace' });
  }
}
