import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dateInput', 'restricted'];

  connect() {
    this.boundChange = this.change.bind(this);
    this.dateInputTarget.addEventListener('change', this.boundChange);

    this.change();
  }

  disconnect() {
    this.dateInputTarget.removeEventListener('change', this.boundChange);
  }

  change() {
    this.restrictedTargets.forEach((target) => {
      target.disabled = this.isFuture();
    });
  }

  isFuture() {
    return new Date(this.dateInputTarget.value) > new Date();
  }
}
