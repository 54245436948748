import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.element.dataset.action = 'student-selector--component#update';
  }

  update(event) {
    const url = this.urlValue.replace(':slug', event.target.value);

    Turbo.visit(url);
  }
}
