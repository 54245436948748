import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (!this.modal) {
      console.warn('Missing parent element with class "modal".');
      return;
    }

    if (!this.modalBox) {
      console.warn('Missing parent element with class "modal-box"');
      return;
    }

    this.element.dataset.action =
      'turbo:submit-end->turbo-modal--component#submitEnd \
       turbo:before-render@document->turbo-modal--component#closeBeforeRender \
       keyup@window->turbo-modal--component#closeWithKeyboard \
       click@window->turbo-modal--component#closeBackground';

    this.modal.classList.add('modal-open');
  }

  // Close dialog with animation
  closeDialog(event) {
    this.modal.classList.remove('modal-open');

    this.modal.ontransitionend = () => {
      this.modal.ontransitionend = null;

      // Remove src reference from parent frame element (just to clean up)
      this.element.parentElement?.removeAttribute('src');

      this.element.remove();
      if (event?.detail.resume) event.detail.resume();
    };
  }

  // Ensure to close dialog (with animation) BEFORE Turbo renders new page
  closeBeforeRender(event) {
    event.preventDefault();
    this.closeDialog(event);
  }

  // Close dialog on successful form submission
  submitEnd(event) {
    if (event.detail.success) this.closeDialog();
  }

  // Close dialog when clicking ESC
  closeWithKeyboard(event) {
    if (event instanceof KeyboardEvent && event.code == 'Escape')
      this.closeDialog();
  }

  // Close dialog when clicking outside
  closeBackground(event) {
    if (
      event.target instanceof HTMLElement &&
      this.modalBox.contains(event.target)
    )
      return;

    this.closeDialog();
  }

  get modal() {
    return this.element.closest('.modal');
  }

  get modalBox() {
    return this.element.closest('.modal-box');
  }
}
