import { Controller } from '@hotwired/stimulus';

const RELEVANT_TAGS = 'input, select, textarea, trix-editor';

export default class extends Controller {
  connect() {
    this.initValidations();
  }

  // Based on https://daverupert.com/2017/11/happier-html5-forms/
  initValidations() {
    const tags = this.element.querySelectorAll(RELEVANT_TAGS);

    tags.forEach((tag) => {
      this.listenForInvalid(tag);
      this.listenforInput(tag);
    });
  }

  listenForInvalid(tag) {
    tag.addEventListener(
      'invalid',
      (event) => {
        if (
          tag.type == 'url' &&
          tag.value.indexOf('http://') != 0 &&
          tag.value.indexOf('https://') != 0
        ) {
          event.preventDefault();
          tag.value = `http://${tag.value}`;
        } else {
          tag.classList.add(this.errorClass(tag));
        }
      },
      false,
    );
  }

  listenforInput(tag) {
    tag.addEventListener('input', () => {
      const valid =
        typeof tag.checkValidity === 'function' ? tag.checkValidity() : true;
      if (!valid) return;

      tag.classList.remove(this.errorClass(tag));

      const formControlTag = tag.closest('.form-control');
      if (!formControlTag) return;
      const validationMessageTag = formControlTag.getElementsByClassName(
        'js-validation-message',
      )[0];
      if (!validationMessageTag) return;

      validationMessageTag.classList.add('hidden');
    });
  }

  errorClass(tag) {
    if (tag instanceof HTMLInputElement) {
      if (tag.type == 'checkbox') return 'checkbox-error';
      else if (tag.type == 'radio') return 'radio-error';
      else if (tag.type == 'range') return 'range-error';
      else if (tag.type == 'file') return 'file-error';
      else return 'input-error';
    } else if (tag instanceof HTMLSelectElement) {
      return 'select-error';
    } else if (tag instanceof HTMLTextAreaElement) {
      return 'textarea-error';
    }

    console.error('Unknown tag', tag);
  }
}
